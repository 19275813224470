window.handlePrintElement = function handlePrintElement(selector, id, css) {
    $(selector).on('click', function(e){
        e.stopPropagation();
        e.preventDefault();

        let styles = css;
        styles = styles + "<style type='text/css'>";
        styles = styles + "    .hide-for-print { display: none; }";
        styles = styles + "    body { font-family: sans-serif !important; }";
        styles = styles + "    th { text-align: left; }";
        styles = styles + "</style>";
        styles = styles + "<style type='text/css' media='print'>";
        styles = styles + "    .hide-for-print { display: none; }";
        styles = styles + "    body { font-family: sans-serif !important; }";
        styles = styles + "    a[href]:after, a[href]:visited:after {";
        styles = styles + "        content: normal;";
        styles = styles + "    }";
        styles = styles + "    .search-result {";
        styles = styles + "        display: block;";
        styles = styles + "        width: 100%;";
        styles = styles + "    }";
        styles = styles + "    th { text-align: left; }";
        styles = styles + "    .button, button { color: #fff !important; }";
        styles = styles + "</style>";
        styles = styles + "<script>";
        styles = styles + "document.addEventListener('DOMContentLoaded', function(event) {";
        styles = styles + "    self.print();";
        styles = styles + "    self.close();";
        styles = styles + "});";
        styles = styles + "</" + "script>";

        let DocumentContainer = document.getElementById(id);
        let WindowObject = window.open("", "PrintWindow",
        "width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes");
        WindowObject.document.writeln('<!DOCTYPE html>');
        WindowObject.document.writeln('<html><head><title></title>');
        WindowObject.document.writeln(styles);
        WindowObject.document.writeln('</head><body>');
        WindowObject.document.writeln(DocumentContainer.innerHTML);
        WindowObject.document.writeln('</body></html>');
        WindowObject.document.close();
        WindowObject.focus();
    });
}
