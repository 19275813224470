window.journalsurf = window.journalsurf || {};

(function (namespace) {
  'use static'

  const spinnerSetup = function() {

    let spinner
    let spinDiv

    if (window.Spinner) {
      let opts = {
        lines: 13, length: 20, width: 2, radius: 30,
        corners: 1, rotate: 0, direction: 1,
        color: '#fff', speed: 1, trail: 60,
        shadow: false, hwaccel: false,
        className: 'spinner', zIndex: 2e9,
        top: '50%', left: '50%'
      };

      spinner = new Spinner(opts);
      spinDiv = document.getElementById('spinner');

      $(document).on('get.journalsurf.resource', function() {
        spinner.spin(spinDiv);
      });

  //        $(document).on('got.journalsurf.resource', function() {
  //            spinner.stop();
  //        });
    }

    return {
      spin: function(){
        $(spinDiv).css('display', 'block');
        spinner.spin(spinDiv);
      },
      stop: function(){
        $(spinDiv).css('display', 'none');
        spinner.stop();
      }
    }
  }

  namespace.spinnerSetup = spinnerSetup
}(window.journalsurf))

