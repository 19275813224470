$(document).foundation({
    abide : {
        live_validate : false,
        timeout : 200,
        validators: {
            domainEqualTo: function(el, required, parent) {
              let from   = document.getElementById(el.getAttribute(this.add_namespace('data-domainequalto'))).value,
                  to     = el.value,
                  domain = from.split('@')[1];

              let valid = false;
              if (required || to) {
                valid = RegExp('@' + domain + '$').test(to);
              } else {
                valid = true;
              }

              return valid;
            },
            group_limit: function(el, required, parent) {
              let group = parent.closest('.limit-group');
              let limit = group.attr('data-abide-validator-limit');
              let countC = group.find(':checked').length;
              if(group.is(':hidden') || countC == limit) {
                group.find('small.error').hide();
                //return true so abide can clear any invalid flags on this element
                return true;
              } else {
                group.find('small.error').css({display:'block'});
                //return false and let abide do its thing to make sure the form doesn't submit
                return false;
              }
            }
        }
    },

    equalizer : {
      // Specify if Equalizer should make elements equal height once they become stacked.
      equalize_on_stack: false
    }
});
