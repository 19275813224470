;
window.journalsurf = window.journalsurf || {};

(function(namespace) {

    let handlers = [];
    let handle = function (container_id, form_id, route, success, error, event) {
        // e.g. form_id = 'login-form'
        //      route = 'login'

        $.ajaxSetup({
           headers: { 'X-CSRF-Token' : $('#' + form_id + ' input[name=_token]').attr('value') },
           cache: false
        });

        event = event || 'valid.fndtn.abide';

        if (!handlers[form_id]) {
            let form = $("#" + form_id);
            handlers[form_id] = form.on(event, function(e) {

                $.event.trigger({
                    type: "get.journalsurf.resource"
                });

                $('#' + container_id + ' .message-block').removeClass('show');
                $('#' + container_id + ' .message-block .alert-box').removeClass('success warning info alert secondary');
                $('#' + container_id + ' .error-server').removeClass('show');
                $('#' + container_id + ' .error-server').text('');

                $('#' + form_id + ' .error-server').removeClass('show');
                $('#' + form_id + ' .error-server').text('');

                $.ajax({
                    type: "POST",
                    url : window.document.location.protocol + '//' + window.document.location.host + '/' + route,
                    data : form.serialize(),
                    success : function(data, textStatus, jqXHR){
                        $.event.trigger({
                            type: "got.journalsurf.resource"
                        });

                        // Show the message if it's been supplied
                        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                            let message = jqXHR.responseJSON.message;
                            if ($('#' + container_id + ' .message-block .alert-box').length) {
                              $('#' + container_id + ' .message-block .alert-box').addClass('success');
                              $('#' + container_id + ' .message-block').addClass('show');
                              $('#' + container_id + ' .message').text(message);
                            }
                        }

                        // Call the supplied success function
                        if (success) {
                            success(data, textStatus, jqXHR);
                        }
                    },
                    error : function(jqXHR, textStatus, errorThrown){
                        $.event.trigger({
                            type: "got.journalsurf.resource"
                        });

                        // Show the message if it's been supplied
                        let message;
                        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                            message = jqXHR.responseJSON.message;
                            if ($('#' + container_id + ' .message-block .alert-box').length) {
                              $('#' + container_id + ' .message-block .alert-box').addClass('alert');
                              $('#' + container_id + ' .message-block').addClass('show');
                              $('#' + container_id + ' .message').text(message);
                            }
                        }

                        let errors = {};
                        if (jqXHR.responseJSON && jqXHR.responseJSON.errors) {
                            errors = jqXHR.responseJSON.errors;
                        } else {
                            errors.message = "Invalid response from the server";
                        }
                        for (let key in errors) {
                            $('#' + form_id + ' .error-server.' + key).addClass('show');
                            $('#' + form_id + ' .error-server.' + key).text(errors[key]);
                        };
                        $('#' + form_id + ' .error-server.' + form_id).text(errors.message);
                        $('#' + form_id + ' .error-server.' + form_id).addClass('show');

                        // Call the supplied error function
                        if (error) {
                            error(jqXHR, textStatus, errorThrown);
                        }
                    }
                });
            });
        }

    };

    namespace.form = {
        handle: handle
    };

    return namespace.form;
})(window.journalsurf);
